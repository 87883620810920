.App {

}

pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  *word-wrap: break-word;
  *white-space : normal ;
}

.App-link {
  color: #61dafb;
}
